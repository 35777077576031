import React from "react"
import { FaFacebookSquare, FaInstagram } from "react-icons/fa"
export default [
  {
    icon: <FaFacebookSquare size={30} color="white" />,
    url: "https://www.facebook.com/Heftige-Webseiten-100731614949350/",
  },
  {
    icon: <FaInstagram size={30} color="white" />,
    url: "https://www.instagram.com/heftigewebseiten/",
  },
]
